import Swiper from 'swiper/bundle';

export default class Carousel {
  constructor(element) {
    this.element = element;
    this.options = {
      slidesPerView: 1,
      spaceBetween: 20,

      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        type: 'bullets',
      },

      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
    };
    this.init();
  }

  init() {
    this.setOptions(); // Appelle la méthode setOptions() pour définir les options Swiper
    const swiper = new Swiper(this.element, this.options); // Initialise Swiper avec l'élément et les options définies
  }

  setOptions() {
    this.variant = this.element.getAttribute('data-variant'); // Récupère l'attribut 'data-variant'
    if (this.variant == 'split') {
      // Si l'attribut est 'split', définir le breakpoint pour le mode split
      this.options.breakpoints = {
        768: {
          slidesPerView: 2.5,
        },
      };
    }

    if (this.variant == 'fade') {
      // Si l'attribut est 'fade', définir les options d'effet de fondu pour Swiper
      this.options.fadeEffect = {
        crossFade: true,
      };
      this.options.effect = 'fade';
    }

    if (this.element.hasAttribute('data-autoplay')) {
      // Si l'attribut 'data-autoplay' est présent, définir les options d'autoplay pour Swiper
      this.options.autoplay = {
        delay: 2000,
        disableOnInteraction: false,
        pauseOnHover: true,
      };
    }

    if (this.element.hasAttribute('data-loop')) {
      // Si l'attribut 'data-loop' est présent, activer la boucle pour Swiper
      this.options.loop = true;
    }

    if (this.variant == 'gap') {
      // Si l'attribut est 'gap', définir l'espace entre les slides pour Swiper
      this.options.spaceBetween = 200;
    }
  }
}
