// Classe pour gérer le header
export default class Header {
  // Le constructeur prend un élément en argument et initialise certaines propriétés
  constructor(element) {
    this.element = element; // L'élément HTML correspondant au header
    this.scrollPosition = 0; // La position de la barre de défilement
    this.scrollLimit = 0.3; // La limite de défilement avant que le header ne se cache
    this.lastScrollPosition = 0; // La position précédente de la barre de défilement
    this.html = document.documentElement; // L'objet document HTML

    // Appel de la méthode init() pour initialiser l'écouteur d'événements
    this.init();

    // Appel de la méthode initNavMobile() pour initialiser le menu de navigation mobile
    this.initNavMobile();
  }

  // Méthode pour initialiser l'écouteur d'événements de défilement
  init() {
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  // Méthode pour gérer l'événement de défilement
  onScroll(event) {
    this.lastScrollPosition = this.scrollPosition;
    this.scrollPosition = document.scrollingElement.scrollTop;

    // Appel de la méthode setHeaderState() pour gérer l'état du header
    this.setHeaderState();

    // Appel de la méthode setDirectionState() pour gérer la direction de défilement
    this.setDirectionState();
  }

  // Méthode pour gérer l'état du header
  setHeaderState() {
    const scrollHeight = document.scrollingElement.scrollHeight;
    const shouldHideHeader = !this.element.hasAttribute('data-not-hiding');

    // Vérifie si la barre de défilement a dépassé la limite de défilement et si le header doit être masqué
    if (
      this.scrollPosition > scrollHeight * this.scrollLimit &&
      shouldHideHeader
    ) {
      this.html.classList.add('header-is-hidden');
    } else {
      this.html.classList.remove('header-is-hidden');
    }
  }

  // Méthode pour gérer la direction de défilement
  setDirectionState() {
    if (this.scrollPosition >= this.lastScrollPosition) {
      this.html.classList.add('is-scrolling-down');
      this.html.classList.remove('is-scrolling-up');
    } else {
      this.html.classList.remove('is-scrolling-down');
      this.html.classList.add('is-scrolling-up');
    }
  }

  // Méthode pour initialiser le menu de navigation mobile
  initNavMobile() {
    const toggle = this.element.querySelector('.js-toggle');
    toggle.addEventListener('click', this.onToggleNav.bind(this));
  }

  // Méthode pour gérer l'événement de bascule du menu de navigation mobile
  onToggleNav() {
    this.html.classList.toggle('nav-is-active');
  }
}
