export default class Scrolly {
  constructor(element) {
    this.element = element;
    this.options = {
      rootMargin: '0px',
    };
    this.init();
  }

  // Initialise l'IntersectionObserver pour observer les éléments ayant l'attribut "data-scrolly"
  init() {
    const observer = new IntersectionObserver(
      this.watch.bind(this),
      this.options
    );
    // Récupère tous les éléments avec l'attribut "data-scrolly" dans l'élément donné et les observe
    const items = this.element.querySelectorAll('[data-scrolly]');
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      observer.observe(item);
    }
  }

  // Fonction appelée par l'IntersectionObserver lorsqu'un élément est observé
  watch(entries, observer) {
    for (let i = 0; i < entries.length; i++) {
      const entrie = entries[i];

      const target = entrie.target;

      // Si l'élément est en intersection avec la vue du navigateur, ajoute la classe "is-active"
      if (entrie.isIntersecting) {
        target.classList.add('is-active');
        // Si l'élément a l'attribut "data-no-repeat", cesser de l'observer
        if (target.hasAttribute('data-no-repeat')) {
          observer.unobserve(target);
        }
      } else {
        // Si l'élément n'est pas en intersection avec la vue du navigateur, supprime la classe "is-active"
        target.classList.remove('is-active');
      }
    }
  }
}

// Sélectionnez tous les éléments avec l'attribut "data-scrolly"
var elementsWithScrolly = document.querySelectorAll('[data-scrolly]');

// Parcourez tous les éléments et ajoutez l'attribut "data-no-repeat"
elementsWithScrolly.forEach(function (element) {
    element.setAttribute('data-no-repeat', '');
});